import moment from "moment";
import * as constant from "../CONSTANT";
import axios from 'axios';
//import { saveAs } from 'file-saver';
//import * as fs from 'fs-web';
//import pathex from '../../Errorlogs/error_log.txt'




export const logError = async (apiName, error) => {
  const currentDate = new Date().toLocaleString(); // Get current date and time
  console.log(`Logging error: ${apiName}, ${error.message || error}`);
  
  const logData = { apiName, errorDetails: error.message || error, dateTime: currentDate };
  const logString = JSON.stringify(logData, null, 2);

  // Check if this console log is being triggered
  console.log("Before writing to file", logString);

  // const blob = new Blob([logString + '\n'], { type: 'text/plain' }); // The '\n' adds a new line after each log entry
  // console.log('  blob:', blob);
  // saveAs(blob, 'D:\Eenadu-Charts-New/Eenadu-chart/src/Errorlogs/error_log.txt');

  try {
    // Ensure the URL structure is correct: http://localhost:2417/{apiName}/logError
    // const response = await axios.post(`http://localhost:2417/${apiName}/logError`, logData);
    const baseUrl = window.location.origin;
    const response = await axios.post(`${baseUrl}/${apiName}/logError`, logData);
    console.log('response',response);
    console.log('Error log sent to server',baseUrl);
  } catch (err) {
    console.error('Failed to send log data to server:', err);
    console.log('Error log sent to server',window.location.origin);
  }
};


//   export const logError = async (apiName, error) => {
//     const currentDate = new Date().toLocaleString();
//     const logData = {
//         apiName,
//         errorDetails: error.message || error,
//         dateTime: currentDate
//     };

  
//     try {
//       // Ensure you're sending the request to the correct URL
//       await axios.post(`http://localhost:2417/logError`, logData);
//       console.log("Error logged successfully to the server!");
//   } catch (err) {
//       console.error("Failed to send error log to the server:", err);
//   }
  
// };



let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
export const currentDateAsTmrw = tomorrow;

export const todayDateTime = (dateString) => {
  const date = new Date(dateString);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = days[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `Today is ${dayOfWeek}, ${month} ${day}, ${year}, Time is ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

export const formatDateWithMoment = (dateString) => {
  const formattedDate = moment(dateString).format(
    "dddd, MMMM DD, YYYY, [Time is] HH:mm:ss"
  );
  return `Today is ${formattedDate}`;
};

export const exactTimeFormatForPreSelect = (dateString) => {
  // Parse the input date string
  const parsedDate = new Date(dateString);

  // Define an array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Define an array of day names
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Get the day, month, year, hours, minutes, and seconds
  const day = days[parsedDate.getDay()];
  const month = months[parsedDate.getMonth()];
  const date = parsedDate.getDate();
  const year = parsedDate.getFullYear();
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();
  const seconds = parsedDate.getSeconds();

  // Get the timezone offset in hours and minutes
  const timezoneOffsetHours = Math.floor(parsedDate.getTimezoneOffset() / 60);
  const timezoneOffsetMinutes = Math.abs(parsedDate.getTimezoneOffset() % 60);

  // Construct the timezone offset string without colon
  const timezoneOffsetString = `${
    timezoneOffsetHours < 0 ? "+" : "-"
  }${Math.abs(timezoneOffsetHours)
    .toString()
    .padStart(2, "0")}${timezoneOffsetMinutes.toString().padStart(2, "0")}`;

  // Construct the formatted date string
  const formattedDateString = `${day} ${month} ${date} ${year} ${hours}:${minutes}:${seconds} GMT${timezoneOffsetString} (India Standard Time)`;

  return formattedDateString;
};

export const findTheThumbPositions = (tGridX, tGridY) => {
  // Calculate scale factors
  const widthScaleFactor =
    constant.THUMBNAIL_SLIDER_WIDTH / constant.NEWS_PAPER_WIDTH;
  const heightScaleFactor =
    constant.THUMBNAIL_SLIDER_HEIGHT / constant.NEWS_PAPER_HEIGHT;

  // Calculate new position values

  //forAdjust
  const forAccuracyAdjustX = 0;
  const forAccuracyAdjustY = 0;
  const newX = Math.ceil(tGridX * widthScaleFactor) + forAccuracyAdjustX;
  const newY = Math.ceil(tGridY * heightScaleFactor) + forAccuracyAdjustY;
  
  // Output the rounded position values

  return { newX, newY };
};
export const findTheThumbContPositions = (cGridX, cGridY) => {
  // Calculate scale factors
  const widthScaleFactor =
    constant.THUMBNAIL_SLIDER_WIDTH / constant.NEWS_PAPER_WIDTH;
  const heightScaleFactor =
    constant.THUMBNAIL_SLIDER_HEIGHT / constant.NEWS_PAPER_HEIGHT;

  // Calculate new position values

  //forAdjust
  const forAccuracyAdjustX = 0;
  const forAccuracyAdjustY = 0;
  const newX = Math.ceil(cGridX * widthScaleFactor) + forAccuracyAdjustX;
  const newY = Math.ceil(cGridY * heightScaleFactor) + forAccuracyAdjustY;
  
  // Output the rounded position values

  return { newX, newY };
};

export const convertIntoPx = (inc) => Number(inc) * 96;

/**
 * Calculates the actual size of an ad based on the container's dimensions and a given ratio.
 * @param {number} dimXY - The width or height of the ad in pixels.
 * @param {number} ratioNum - The ratio of the container in which the ad fits.
 * @returns {number} - The calculated size of the ad based on the provided ratio.
 */
export const ratioCalculte = (dimXY, ratioNum) => Math.ceil(dimXY / ratioNum);

/**
 * String to destructure the width and height by passing the string values as example 4x4
 * @param {string} string - accespts the dimension string in with x .ie, 3x3
 * @returns {Array} -returns array of width and height [w,h];
 */

export const splitDimensionStr = (string) => {
  const [w, h] = string?.split(/[xX]/);
  return [w, h];
};

/**
 * Accept the dimension of ad in string form
 * @param {String} string - accept the dimension of the ad in string . Ex: 6x6
 * @param {Number} ratioNum - acept the ratio adapt example in newsPaperGrid or thumbnail - 3 or 7
 * @returns {Array} - array of width, height of ad in px to blend in
 */
export const getAdsSize = (string, ratioNum) => {
  const [width, height] = splitDimensionStr(string);
  const convertPxWidth = convertIntoPx(width);
  const convertPxHeight = convertIntoPx(height);

  const widthPx = ratioCalculte(convertPxWidth, ratioNum);
  const heightPx = ratioCalculte(convertPxHeight, ratioNum);

  return [widthPx, heightPx];
};

export const formatTime = {
  /**
   * Used to conver from yyyy-mm-dd into dd-mm-yyyy
   * @param {string} stringDate - dateString in fromat in yyyy-mm-dd
   * @returns {string} - convert dateString to dd-mm-yyyy
   */
  yyyymmddToddmmyy: (stringDate) => moment(stringDate).format("DD-MM-YYYY"),
};

/**
 * Truncate the text in elipsis text i.e . text...
 * @param {string} string - accept string 
 * @param {number} maxCount - number of chars to show in ellipsis text
 * @returns {string} - ip: String, 3 -> op: Str.....
 */
export const truncateElipseText = (string, maxCount) => `${string?.substring(0,maxCount)}.....`
