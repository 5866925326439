import { call, put, takeEvery } from "redux-saga/effects";
import httpService from "../httpService";
import * as apiUrl from '../apiUrl';
import { logError } from '../../Utils/Utils';


async function fetchallListOfAdsApiCall(payloadDetails){
    try {
        // const postingPayload = {
        //     "page_id": 128,
        //     "publish_date":"2023-09-21"
        // }
        const postingPayload = {
            "page_id": payloadDetails?.pageNumber?.value,
            "publish_date":payloadDetails?.dateValue,
            "ad_type_id": payloadDetails?.adType?.value,
            "position_id": payloadDetails?.position?.value,
            "paper_number":payloadDetails?.paperNumber?.value,
            "edition_type": payloadDetails?.editionType?.value,
            "region_id": payloadDetails?.regionId
        }
        const response = await httpService.postCallMethod(apiUrl.fetchAdsListAll, postingPayload)
        // console.log('response', response)
        
        return response; 
    } catch (error) {
        logError('fetchAdsListAll', error);
        throw error ; 
    }
}



function* fetchAllAdsbyPostingParams(action){
    try {
        const fetcheadsbypositingDatas = yield call(()=> fetchallListOfAdsApiCall(action.payload));
        console.log('fetcheadsbypositingDatas', fetcheadsbypositingDatas)
        yield put({type:'FETCH_ADS_BY_POST_SUCCESS', fetcheadsbypositingDatas: fetcheadsbypositingDatas})
        yield put({type: 'PRIVATE_DB_SEND_COPY_FROM_FETCHED_POST', copyFetchedAdsList : fetcheadsbypositingDatas})//privateDb_1stRequirement
        yield put({type: 'MOVE_AD_FROM_FILTERED_TO_AD_POSITIONER', fetchedResults : fetcheadsbypositingDatas})//dbCombined_2nd requiremnet

    } catch (error) {
        yield put({type: 'FETCH_ADS_BY_POST_FAILED',  fetcheadsbypositingErrors: error})
    }
}


function* fetchedadsbypostingSaga(){
    yield takeEvery('FETCH_ADS_BY_POST', fetchAllAdsbyPostingParams)
}
export default fetchedadsbypostingSaga; 