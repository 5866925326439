import { useState } from "react";
import { Col, Container, Row, Modal, ModalBody, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEdit, AiOutlineSchedule } from "react-icons/ai";
import { IoIosCheckmarkCircle } from "react-icons/io";
import {
  moveTableAdToPositioner,
  editSlugNo,
  editSlugNoCleanUp,
  fetchParamsListData,
} from "../../ReduxState/Actions/actionAd";
import EditableInput from "../EditableInput/EditableInput";
import Loader from "../Loader/Loader";
import "./TableAdsList.scss";
import { formatTime } from "../../Utils/Utils";

export default function TableAdsList(props) {
  const {
    // eslint-disable-next-line no-unused-vars
    tableData: { tableData, key: tableKey, title },
    regionVal,
  } = props;
  const {
    adPositionedWithGrid: dbWithGrid,
    contentPositionedWithGrid: dbWithGridContent,
    // eslint-disable-next-line no-unused-vars
    adListedWithoutGrid: dbWithoutGrid,
  } = useSelector((state) => state.dbpostadscombined);
  const { slugEditLoading, slugEditSucceedData, slugEditError } = useSelector(
    (state) => state.editslugofad
  );
  const [tableRowData, setTableRowData] = useState({});
  const [tableModal, setTableModal] = useState(false);
  const dispatch = useDispatch();

  const addTodayAdToPositioning = (e, item) => {
    e.preventDefault();
    dispatch(moveTableAdToPositioner([item]));
  };

  const rowData = (e, data) => {
    e.preventDefault();
    setTableModal(true);
    setTableRowData(data);
  };

  const closeTableModal = (e) => {
    e.preventDefault();
    setTableModal(false);
    setTableRowData({});
  };

  const changedSlugNoValue = (val) => {
    setTableRowData((prev) => ({
      ...prev,
      slug_number: val,
    }));
  };

  const submitEditTabledata = (e) => {
    e.preventDefault();
    dispatch(
      editSlugNo({
        id: tableRowData?.id,
        slugNumber: tableRowData?.slug_number,
      })
    );
    setTimeout(() => {
      dispatch(fetchParamsListData(regionVal?.value)); //->to call api again to display updated tableData.
      dispatch(editSlugNoCleanUp());
      setTableModal(false);
      setTableRowData({});
    }, 3000);
  };

  return (
    <div>
      <Container>
        <Modal centered show={tableModal}>
          <ModalBody>
            <div>
              <table className="mx-auto">
                <tbody>
                  <tr>
                    <th>Agent Name:</th>
                    <td>{tableRowData?.agent_name}</td>
                  
                    </tr>
                  <tr>
                    <th>CIO Number:</th>
                    <td>{tableRowData?.cio_number}</td>
                  </tr>
                  <tr>
                    <th>Ad Id:</th>
                    <td>{tableRowData?.id}</td>
                  </tr>
                </tbody>
              </table>
              <div className="edit-slug">
                <div className="title">Edit slug number: </div>
                <div className="edit-field">
                  <EditableInput
                    name={"slugNo"}
                    placeholder={"Enter Slug number"}
                    inputData={tableRowData?.slug_number}
                    settledInputvalue={changedSlugNoValue}
                  />
                </div>
                <div className="btns">
                  {slugEditLoading ? (
                    <span className="mx-auto">
                      <Loader />
                    </span>
                  ) : slugEditError ? (
                    <span className="mx-auto invalid">
                      {slugEditError?.error}
                    </span>
                  ) : slugEditSucceedData ? (
                    <span className="mx-auto valid">
                      {" "}
                      Slug number edited successfully{" "}
                    </span>
                  ) : (
                    <>
                      <Button onClick={closeTableModal}>Cancel</Button>
                      <Button onClick={submitEditTabledata}>Submit</Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Col>
          <Row>
            <Col>
              <div className="tableList">
                <table>
                  <thead>
                    <tr>
                      {tableKey === "classifieds" ? (
                        <>
                          <th>Publish Date</th>
                          <th>Description</th>
                          <th>No of lines</th>
                          <th>Publication</th>
                          <th>Slug number</th>
                        </>
                      ) : (
                        <>
                          <th>Agent name</th>
                          <th>Customer name</th>
                          <th>CIO number</th>
                          {/* <th>Ad Type</th> */}
                          <th>Page No</th>
                          <th>Position</th>
                          <th>Payment</th>
                          <th>Publish Date</th>
                          <th>Slug number</th>
                          <th>Size</th>
                          {/* <th>Action</th> */}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.length ? (
                      tableData?.map((el, i) =>
                        tableKey === "classifieds" ? (
                          <tr
                            key={el?.id + "*" + i + "**"}
                            className={
                              el?.postponed_ad ? "postPoned" : el?.is_reserve_advert ? "isReserved" : undefined
                            }
                            
                          >
                            <td>{el?.publish_date ? formatTime.yyyymmddToddmmyy(el?.publish_date) : "-"}</td>
                            <td>{el?.description ? el?.description : "-"}</td>
                            <td>{el?.no_of_lines ? el?.no_of_lines : "-"}</td>
                            <td>{el?.publication ? el?.publication : "-"}</td>
                            <td>
                              <div className="slug">
                                <span>
                                  {el?.slug_number ? el?.slug_number : "-"}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr
                            key={el?.id + "*" + i}
                            className={
                              el?.postponed_ad ? "postPoned" : el?.is_reserve_advert ? "isReserved" : undefined
                            }
                          >
                            <td>{el?.agent_name ? el?.agent_name : "-"}
                            {console.log('object', el) }

                            </td>
                            <td>
                              {el?.customer_name ? el?.customer_name : "-"}
                            </td>
                            <td>{el?.cio_number ? el?.cio_number : "-"}</td>
                            {/* <td className="adTypeTd">
                              {el?.ad_type ? el?.ad_type : "-"}
                            </td> */}
                            <td>{el?.page_name ? el?.page_name : "-"}</td>
                            <td>
                              {el?.position_name ? el?.position_name : "-"}
                            </td>
                            <td>
                              {el?.amount_status ? el?.amount_status : "-"}
                            </td>
                            <td>{el?.publish_date ? formatTime.yyyymmddToddmmyy(el?.publish_date) : "-"}</td>
                            <td>
                              <div className="slug">
                                <span>
                                  {el?.slug_number ? el?.slug_number : "-"}
                                </span>
                                <span>
                                  <AiFillEdit
                                    onClick={(e) => rowData(e, el)}
                                    size={20}
                                    title="Edit slug number"
                                  />
                                </span>
                              </div>
                            </td>
                            <td>{el?.size ? el?.size : "-"}</td>
                            {/* <td>
                              <span className="actionBtn">
                                {dbWithGrid?.find(
                                  (posEl) => posEl?.id === el?.id
                                ) ? (
                                  <div className="added actWrap">
                                    <IoIosCheckmarkCircle
                                      size={21}
                                      color="green"
                                    />
                                    <span className="">Scheduled</span>
                                  </div>
                                ) : (
                                  <div className="add actWrap">
                                    <AiOutlineSchedule
                                      size={20}
                                      onClick={(e) =>
                                        addTodayAdToPositioning(e, el)
                                      }
                                    />
                                    <span>Schedule</span>
                                  </div>
                                )}
                              </span>
                            </td> */}
                          {console.log('object', el)}

                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="12">
                          <div className="noRecordsTodayTable">
                            No records found.
                          </div>
                        </td>
                      </tr>
                    )}
                    
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
  );
}
