import { call, put, takeEvery } from "redux-saga/effects";
import httpService from "../httpService";
import * as apiUrl from '../apiUrl';
import { logError } from '../../Utils/Utils';

// API call for deleting content
async function deleteContentApiCall(payload) {
  try {
    console.log("Payload received in deleteContentApiCall:", payload);
    const response = await httpService.postCallMethod(apiUrl.deleteContentUrl, {
      id: payload.deletingId,
    });
    return response;
  } catch (error) {
    logError('deleteContentUrl', error);
    throw error;
  }
}

// Saga Worker
function* deleteContentWorker(action) {
    console.log("Action received in deleteContentWorker:", action); // Log the action payload
    try {
        const response = yield call(() => deleteContentApiCall(action.payload));
        console.log("API Response:", response); // Log the API response
        if (response.status_code === 200) {
            yield put({
                type: "REMOVE_CONTENT_DETAILS_SUCCESS",
                payload: action.payload.deletingId,
            });
        } else {
            yield put({
                type: "REMOVE_CONTENT_DETAILS_FAILED",
                error: response.message,
            });
        }
    } catch (error) {
        console.error("Error in deleteContentWorker:", error.message);
        yield put({
            type: "REMOVE_CONTENT_DETAILS_FAILED",
            error: error.message,
        });
    }
}

function* deleteContentSaga() {
    console.log("deleteContentSaga is watching for REMOVE_CONTENT_DETAILS");
    yield takeEvery("REMOVE_CONTENT_DETAILS", deleteContentWorker);
}

export default deleteContentSaga;

