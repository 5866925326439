import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "../Login/Login";
import PagenotFound from "../PagenotFound/PagenotFound";
import useAuthStatus from "../CustomHooks/useAuthStatus";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Scheduler from "../Scheduler/Scheduler";

export default function Layout() {
  const { isLoggedin } = useAuthStatus();

  return (
    <>
      <Router>
        {console.log('isLoggedin:', isLoggedin)}
        {!isLoggedin ? (
          <Routes>
            // <Route path="/" element={<Login />} />
            // <Route path="/scheduler" element={<Navigate to={"/"} />} />
            // <Route path="*" element={<PagenotFound />} />
            <Route path="/" element={<Navigate to="/sp" replace />} />
            <Route path="/sp" element={<Login />} />
            <Route path="/sp/scheduler" element={<Navigate to={"/"} />} />
            <Route path="*" element={<PagenotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to={"/scheduler"} />} />
            <Route
              path="/scheduler"
              element={
                <ProtectedRoute>
                  <Scheduler />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PagenotFound />} />
          </Routes>
          
        )}
        {isLoggedin && <CurrentPath />}
      </Router>
      
    </>
  );
}

function CurrentPath() {
  const location = useLocation(); // useLocation now works because it is inside the Router
  console.log('Current Path:', window.location.href);
  return null;
}
