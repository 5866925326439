import { call, put, takeEvery } from "redux-saga/effects";
import httpService from "../httpService";
import * as apiUrl from '../apiUrl';
import { logError } from '../../Utils/Utils';

async function submitingApiCall(adsArrayList){
    // adsArrayList--> it has to be mappedTransformed for Db
    const formatedLoad = {
        "scheduling_details":adsArrayList
    }
    try {
        const response = await httpService.postCallMethod(apiUrl.submitAds, formatedLoad)
        return response;
    } catch (error) {
        logError('submitAds', error);
        throw error;
    }

}

function* submitOwnDbPosition(action){
    try {
        const result = yield call(()=>submitingApiCall(action?.payload))
        yield put({type: 'SUBMITE_DB_POSITION_SUCCESS', successInSubmit : result})

    } catch (error) {
        yield put({type: 'SUBMITE_DB_POSITION_FAILED', errorInSubmitting : error})
    }
}

function* submitdbpositionSaga(){
    yield takeEvery('SUBMITE_DB_POSITION', submitOwnDbPosition)
}

export default submitdbpositionSaga; 